/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import { ASMRModelChange } from '@/store/asmr/types';
const namespace: string = 'asmr';

export default class AsmrModelChoicePage extends Vue {
	@State('asmr') asmr: ASMRModelChange | undefined;
	@Action('getAsmrModels', {namespace}) getAsmrModels: any;
	@Mutation('setModelItem', {namespace}) setModelItem: any;
	@Getter('getModelsAsmr', {namespace}) getModelsAsmr: any;

	// modelItem: string = '';

	asmrVideoChoise(item: string) {
		this.setModelItem(item);
		this.$router.push({ name: 'asmr-video-choice' }).then(r => r);
	}
	created() {
		this.getAsmrModels();
	}
}
